// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-policy-advocacy-program-detail-tsx": () => import("/opt/build/repo/src/templates/PolicyAdvocacyProgramDetail.tsx" /* webpackChunkName: "component---src-templates-policy-advocacy-program-detail-tsx" */),
  "component---src-templates-alternatives-to-incarceration-program-detail-tsx": () => import("/opt/build/repo/src/templates/AlternativesToIncarcerationProgramDetail.tsx" /* webpackChunkName: "component---src-templates-alternatives-to-incarceration-program-detail-tsx" */),
  "component---src-templates-alternatives-program-detail-tsx": () => import("/opt/build/repo/src/templates/AlternativesProgramDetail.tsx" /* webpackChunkName: "component---src-templates-alternatives-program-detail-tsx" */),
  "component---src-templates-direct-service-program-detail-tsx": () => import("/opt/build/repo/src/templates/DirectServiceProgramDetail.tsx" /* webpackChunkName: "component---src-templates-direct-service-program-detail-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blueprint-ar-tsx": () => import("/opt/build/repo/src/pages/blueprint-ar.tsx" /* webpackChunkName: "component---src-pages-blueprint-ar-tsx" */),
  "component---src-pages-blueprint-fr-tsx": () => import("/opt/build/repo/src/pages/blueprint-fr.tsx" /* webpackChunkName: "component---src-pages-blueprint-fr-tsx" */),
  "component---src-pages-blueprint-port-tsx": () => import("/opt/build/repo/src/pages/blueprint-port.tsx" /* webpackChunkName: "component---src-pages-blueprint-port-tsx" */),
  "component---src-pages-blueprint-sp-tsx": () => import("/opt/build/repo/src/pages/blueprint-sp.tsx" /* webpackChunkName: "component---src-pages-blueprint-sp-tsx" */),
  "component---src-pages-blueprint-tsx": () => import("/opt/build/repo/src/pages/blueprint.tsx" /* webpackChunkName: "component---src-pages-blueprint-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

