/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Apply Global Styles
// import './src/styles/global.css';
import './src/styles/screen.css';
import './src/styles/reset.scss';
import './src/styles/fonts.scss';
import './src/styles/mixins.scss';
import './src/styles/variables.scss';
import './src/styles/main.scss';
